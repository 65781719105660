import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// This would typically come from an API or database
const projectDetails = {
  'fluid-sim': {
    title: "SPH Fluid Simulation",
    description: "Real-time, physics-based fluid simulation with interactive capabilities",     
    fullDescription: "A high-performance fluid dynamics simulator that uses Smoothed Particle Hydrodynamics (SPH) to create realistic fluid behavior",
    image: "/images/fluid-sim.png",
    category: "Graphics & Simulation",
    tech: ["C++", "OpenGL", "GLSL", "GLM"],
    github: "https://github.com/alexisdinardo/fluid-sim",
    demoVideo: "/videos/fluid_sim_vid.mp4",
    features: [
    "Realistic fluid physics using Smoothed Particle Hydrodynamics",
    "Interactive mouse-based fluid manipulation",
    "Spatial partitioning for efficient neighbor finding",
    "Real-time performance metrics and visualization"
    ],
    implementation: [
    "Implemented SPH algorithm with density, pressure, and viscosity calculations",
    "Created efficient spatial partitioning grid for collision detection",
    "Built interactive mouse-based force application system",
    "Designed shader-based particle rendering pipeline",
    "Next steps: Add GPU acceleration with CUDA and Metal"
    ]
    },
'ray-tracer': {
    title: "Ray Tracer",
    description: "Physically-based ray tracer with advanced rendering features",
    fullDescription: `A ray tracer that implements core computer graphics concepts`,
    image: "/images/image5.jpg",
    category: "Computer Graphics",
    tech: ["C++"],
    images: [
      "/images/image6.jpg",
      "/images/image7.jpg",
      "/images/image8.jpg"
    ],
    features: [
      "Phong shading model with ambient, diffuse, and specular components",
      "Reflective materials with adjustable reflectivity (0-1 range)",
      "Efficient ray-object intersection for spheres and planes",
      "Multiple light sources with point and ambient lighting",
      "Recursive ray tracing with configurable depth limit"
    ],
    implementation: [
      "Implemented Phong shading with separate ambient, diffuse, and specular components",
      "Developed reflective shader that combines base material with reflection",
      "Created ray-sphere intersection using quadratic equation solving",
      "Implemented shadow ray calculations with light source visibility testing",
      "Designed recursive ray tracing system with depth control",
    ]
  },
'geo-mod': {
    title: "3D Shape Renderer",
    description: "Interactive 3D Shape Visualization with OpenGL",
    fullDescription: `A real-time 3D shape renderer that demonstrates fundamental computer graphics principles`,
    image: "/images/donut.png",
    category: "Computer Graphics",
    tech: ["C", "OpenGL", "GLUT", "Linear Algebra", "GLSL"],
    github: "https://github.com/alexisdinardo/3DShapeRenderer",
    demoVideo: "/videos/shapes.mp4",
    features: [
      "Parametric shape generation (Sphere, Torus, Spring)",
      "Real-time interactive rotation and zoom",
      "Custom 3D model file import system",
      "Dynamic color assignment per triangle",
      "Depth buffer for proper 3D rendering"
    ],
    implementation: [
      "Developed parametric equations for complex 3D shapes",
      "Implemented transformation matrices for rotation and scaling",
      "Created efficient vertex buffer management system",
      "Built robust file parsing for custom 3D models",
      "Optimized rendering pipeline for smooth performance"
    ]
},
'vision': {
  title: "Computer Vision Projects",
  description: "Comprehensive computer vision implementations for my Computer Vision course at Pitt",
  fullDescription: `A collection of advanced computer vision projects showcasing 
  a range of techniques for image analysis and pattern recognition. This includes 
  three main components: (1) Bag of Words Classification for treating images as collections of 
  visual features for effective categorization, (2) Hough Transform implementation for detecting 
  geometric shapes in complex images through parametric space transformation, and (3) a custom 
  Deep Learning framework with CNN architectures for image recognition tasks.`,
  image: "/images/vision.png",
  category: "Computer Vision & Machine Learning",
  tech: ["Python", "OpenCV", "PyTorch", "TensorFlow", "NumPy"],
  github: [
    "https://github.com/alexisdinardo/BoWClassification",
    "https://github.com/alexisdinardo/HoughTransform",
    "https://github.com/alexisdinardo/DeepLearning"
  ],
  features: [
    "Feature extraction and classification using BoW model",
    "Geometric pattern detection with Hough Transform",
    "CNN-based deep learning for complex image recognition",
    "Edge detection and parameter space visualization",
    "Transfer learning and data augmentation capabilities"
  ],
  implementation: [
    "Developed visual feature extraction and vocabulary generation for BoW",
    "Implemented parametric transformations for shape detection",
    "Created neural network architecture with custom components",
    "Built preprocessing pipelines for various image analysis tasks",
    "Designed visualization tools for model interpretation and evaluation",
    "Optimized algorithms for performance across different image complexities"
  ]
},
  'scraps': {
    title: "Scraps Social Media",
    description: "Mentally Healthy Social Media Platform",
    fullDescription: `A social media platform that takes away negativity and brings back creativity to online social communities
 `,
    image: "/images/scrapswireframe.jpeg",
    category: "Mobile Development",
    tech: ["React Native", "Sketch", "Firebase"],
    demoVideo: "/videos/final scraps.mp4",
    features: [
      "Home page with your friends' most recent Scraps",
      "Explore page with different interest categories",
      "Post from your camera only",
      "Message page with a chat interface",
      "Profile page (or your Scrapbook) where you can rearrange your Scraps"
    ],
    implementation: [
      "Designed UI and user flow on Sketch",
      "Developed the web, iOS, and Android versions of th app using React Native",
      "Used Firebase to hold, send, and retrieve demo data"
    ]
  },
'startPNC': {
  title: "Start PNC",
  description: "Financial Education App for Children",
  fullDescription: `A mobile application designed to teach children aged 6-12 about financial literacy through interactive features and gamification:`,
  image: "/images/start.png", 
  category: "Mobile Development",
  tech: ["Swift", "Sketch", "InVision"],
  demoVideo: "/videos/startPNC.mp4",
  features: [
    "Interactive dashboard with account overview and financial tips",
    "Wallet functionality for money requests and transfers",
    "Savings account with wishlist creation",
    "Task management system for parent-assigned activities",
    "Progress tracking with point-based rewards system"
  ],
  implementation: [
    "Designed user flows and wireframes in Sketch",
    "Created interactive prototype in InVision",
    "Developed iOS application using Swift",
    "Implemented child-friendly UI with engaging visual elements"
  ]
},
  // Add other projects here
};

function VideoModal({ isOpen, onClose, videoUrl, posterUrl }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            padding: '2rem'
          }}
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '1000px',
              maxHeight: '90vh',
              borderRadius: '12px',
              overflow: 'hidden',
              background: 'var(--card-background)',
              boxShadow: 'var(--box-shadow-light)'
            }}
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                background: 'rgba(0, 0, 0, 0.5)',
                border: 'none',
                color: 'white',
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <video
              controls
              style={{
                width: '100%',
                display: 'block',
                maxHeight: '90vh'
              }}
              poster={posterUrl}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function ProjectDetail() {
  const { slug } = useParams();
  const project = projectDetails[slug];
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      style={{
        padding: '120px 4rem',
        maxWidth: '1200px',
        margin: '0 auto'
      }}
    >
      <Link 
        to="/projects"
        style={{
          textDecoration: 'none',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '0.5rem',
          marginBottom: '2rem',
          color: 'var(--accent-color)',
          fontSize: '1.1rem'
        }}
      >
        <code style={{ background: 'none', padding: 0 }}>←</code>
        <span>Back to Projects</span>
      </Link>

      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '4rem',
        alignItems: 'start'
      }}>
        <div>
          <div style={{ marginBottom: '2rem' }}>
            <code style={{ 
              color: 'var(--accent-color)',
              fontSize: '1.1rem',
              marginBottom: '1rem',
              display: 'block'
            }}>
              project.details
            </code>
            <h1 style={{
              fontSize: '3.5rem',
              marginBottom: '1.5rem',
              fontWeight: '600',
              lineHeight: '1.2'
            }}>
              {project.title}
            </h1>
            <p style={{
              fontSize: '1.2rem',
              color: 'var(--secondary-color)',
              lineHeight: '1.8',
              marginBottom: '2rem'
            }}>
              {project.fullDescription}
            </p>
          </div>

          <div style={{ marginBottom: '3rem' }}>
            <h3 style={{
              fontSize: '1.4rem',
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <code style={{ color: 'var(--accent-color)' }}>const</code> Tech Stack
            </h3>
            <div style={{
              display: 'flex',
              gap: '0.8rem',
              flexWrap: 'wrap'
            }}>
              {project.tech.map((tech, index) => (
                <code key={index} style={{
                  background: 'var(--code-bg)',
                  color: 'var(--accent-light)',
                  padding: '0.5rem 1rem',
                  borderRadius: '4px'
                }}>
                  {tech}
                </code>
              ))}
            </div>
          </div>

          <div style={{ marginBottom: '3rem' }}>
            <h3 style={{
              fontSize: '1.4rem',
              marginBottom: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <code style={{ color: 'var(--accent-color)' }}>function</code> Key Features
            </h3>
            <ul style={{
              listStyle: 'none',
              padding: 0,
              display: 'grid',
              gap: '1rem'
            }}>
              {project.features.map((feature, index) => (
                <li key={index} style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  gap: '0.5rem'
                }}>
                  <code style={{ color: 'var(--accent-color)' }}>→</code>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 style={{
              fontSize: '1.4rem',
              marginBottom: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <code style={{ color: 'var(--accent-color)' }}>class</code> Implementation
            </h3>
            <ul style={{
              listStyle: 'none',
              padding: 0,
              display: 'grid',
              gap: '1rem'
            }}>
              {project.implementation.map((item, index) => (
                <li key={index} style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  gap: '0.5rem'
                }}>
                  <code style={{ color: 'var(--accent-color)' }}>→</code>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div style={{ position: 'sticky', top: '120px' }}>
          <div className="fun-card" style={{
            overflow: 'hidden',
            borderLeft: '4px solid var(--accent-color)',
            maxWidth: project.slug === 'startPNC' ? '400px' : '800px',
            margin: '0 auto',
            background: 'var(--card-background)'
          }}>
            <img 
              src={project.image} 
              alt={project.title}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
                objectFit: 'contain'
              }}
            />
            <div style={{ padding: '2rem' }}>
              <div style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                marginBottom: '2rem'
              }}>
                {project.images ? (
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '1.5rem',
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto'
                  }}>
                    {project.images.map((img, index) => (
                      <div key={index} style={{
                        position: 'relative',
                        paddingTop: '75%',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        background: 'var(--card-background)',
                        boxShadow: 'var(--box-shadow-light)'
                      }}>
                        <img 
                          src={img} 
                          alt={`${project.title} ${index + 1}`}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {project.github && (
                      Array.isArray(project.github) ? (
                        project.github.map((url, index) => {
                          const projectNames = {
                            "https://github.com/alexisdinardo/BoWClassification": "Bag of Words",
                            "https://github.com/alexisdinardo/HoughTransform": "Hough Transform",
                            "https://github.com/alexisdinardo/DeepLearning": "Deep Learning"
                          };
                          return (
                            <a 
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fun-card"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '0.8rem',
                                padding: '1rem 2rem',
                                textDecoration: 'none',
                                color: '#000000',
                                fontSize: '1.1rem',
                                fontWeight: '500',
                                background: 'var(--card-background)',
                                borderRadius: '8px',
                                transition: 'transform 0.2s ease'
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                              </svg>
                              <code style={{ background: 'none', padding: 0, color: '#000000' }}>{projectNames[url] || 'View Code'}</code>
                            </a>
                          );
                        })
                      ) : (
                        <a 
                          href={project.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="fun-card"
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '0.8rem',
                            padding: '1rem 2rem',
                            textDecoration: 'none',
                            color: '#000000',
                            fontSize: '1.1rem',
                            fontWeight: '500',
                            background: 'var(--card-background)',
                            borderRadius: '8px',
                            transition: 'transform 0.2s ease'
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                          </svg>
                          <code style={{ background: 'none', padding: 0, color: '#000000' }}>View Code</code>
                        </a>
                      )
                    )}
                    {project.demoVideo && (
                      <button
                        onClick={() => setIsVideoModalOpen(true)}
                        className="fun-card"
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: '0.8rem',
                          padding: '1rem 2rem',
                          border: 'none',
                          cursor: 'pointer',
                          color: '#000000',
                          fontSize: '1.1rem',
                          fontWeight: '500',
                          background: 'var(--card-background)',
                          borderRadius: '8px',
                          transition: 'transform 0.2s ease'
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                        </svg>
                        <code style={{ background: 'none', padding: 0, color: '#000000' }}>View Demo</code>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoUrl={project.demoVideo}
        posterUrl={project.image}
      />
    </motion.div>
  );
}

export default ProjectDetail;