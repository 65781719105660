import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Profile from './Profile';
import Background from './Background';
import Skills from './Skills';
import Interests from './Interests';

function HomeAboutCombined() {
  const profileVariants = {
    hidden: { 
      opacity: 0,
      y: 30
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: [0.22, 1, 0.36, 1],
        staggerChildren: 0.4,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.2,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.2 }}
      style={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        lineHeight: '1.8',
        display: 'flex',
        flexDirection: 'column',
        gap: 'clamp(2rem, 4vw, 4rem)',
        padding: 'clamp(40px, 5vw, 80px) clamp(1rem, 3vw, 2rem)'
      }}
    >
      <motion.div 
        className="section-container"
        variants={profileVariants}
        initial="hidden"
        animate="visible"
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%'
        }}
      >
        <motion.div
          variants={itemVariants}
          style={{
            position: 'relative',
            zIndex: 1,
            width: '100%'
          }}
        >
          <Profile />
        </motion.div>
        
        {/* Animated background elements */}
        <motion.div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            pointerEvents: 'none',
            overflow: 'hidden'
          }}
        >
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              style={{
                position: 'absolute',
                width: 'clamp(200px, 40vw, 300px)',
                height: 'clamp(200px, 40vw, 300px)',
                borderRadius: '50%',
                background: `rgba(100, 255, 218, ${0.03 - i * 0.01})`,
                filter: 'blur(clamp(20px, 4vw, 40px))',
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                transform: 'translate(-50%, -50%)'
              }}
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.3, 0.5, 0.3],
                x: [0, 'clamp(20px, 4vw, 50px)', 0],
                y: [0, 'clamp(-20px, -4vw, -50px)', 0]
              }}
              transition={{
                duration: 12 + i * 4,
                repeat: Infinity,
                ease: "easeInOut",
                delay: i * 3
              }}
            />
          ))}
        </motion.div>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true, margin: "-50px" }}
        style={{
          width: '100%'
        }}
      >
        <Background />
      </motion.div>
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true, margin: "-50px" }}
        style={{
          width: '100%'
        }}
      >
        <Skills />
      </motion.div>
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true, margin: "-50px" }}
        style={{
          width: '100%'
        }}
      >
        <Interests />
      </motion.div>
    </motion.section>
  );
}

export default HomeAboutCombined; 