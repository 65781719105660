import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Skills() {
  const [activeTab, setActiveTab] = useState('languages');
  
  // Skill data with detailed information
  const skillsData = {
    languages: [
      { name: "C/C++", level: 85, years: "3D Graphics and Rendering, Operating Systems, Systems Software", projects: 15 },
      { name: "Python", level: 85, years: "Computer Vision, Foundations of AI, Data Science, ", projects: 12 },
      { name: "HTML/CSS", level: 80, years: "Web Development", projects: 20 },
      { name: "Java", level: 75, years: "Algorithms and Data Structures", projects: 6 },
      { name: "Swift", level: 60, years: "Internship at PNC and Personal Projects", projects: 7 }
    ],
    frameworks: [
      { name: "OpenGL", level: 86, years: "Computer Graphics", projects: 18 },
      { name: "OpenUSD", level: 86, years: "Personal Projects", projects: 18 },
      { name: "React/React Native", level: 86, years: "Capstone Project", projects: 18 },
      { name: "PyTorch", level: 80, years: "Computer Vision", projects: 5 },
      { name: "TensorFlow", level: 82, years: "Computer Vision", projects: 7 }
    ],
    tools: [
      { name: "Git", level: 88, years: "Everything", projects: 25 },
      { name: "Figma", level: 90, years: "Human Computer Interaction", projects: 15 },
      { name: "Adobe Suite", level: 92, years: "Digital Media", projects: 20 },
      { name: "Maya/Blender", level: 85, years: "Personal Projects", projects: 12 }
    ]
  };

  // Category information
  const categoryInfo = {
    languages: {
      title: "Programming Languages",
      description: "Core programming languages I use to build applications and systems.",
      icon: "💻"
    },
    frameworks: {
      title: "Frameworks & Libraries",
      description: "Tools and frameworks I leverage to build applications efficiently.",
      icon: "⚛️"
    },
    tools: {
      title: "Design & Development Tools",
      description: "Professional tools I use for design, version control, and deployment.",
      icon: "🔧"
    }
  };


  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.07 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <div className="skills-container" style={{
      padding: '2rem 2rem',
      maxWidth: '1200px',
      margin: '0 auto',
      position: 'relative'
    }}>
      {/* Section header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          marginBottom: '3rem',
          textAlign: 'center'
        }}
      >
        <h2 style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          marginBottom: '1rem',
          color: 'var(--primary-color)',
          position: 'relative',
          display: 'inline-block'
        }}>
          Skills
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            transition={{ duration: 0.8, delay: 0.2 }}
            style={{
              position: 'absolute',
              left: 0,
              bottom: '-5px',
              height: '3px',
              background: 'var(--accent-color)',
              borderRadius: '2px'
            }}
          />
        </h2>
      </motion.div>

      {/* Main section with tabs and content */}
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(250px, 1fr) 3fr',
        gap: '2.5rem',
        alignItems: 'start'
      }}>
        {/* Tabs navigation - left side */}
        <div style={{
          background: 'var(--card-background)',
          borderRadius: '12px',
          boxShadow: 'var(--box-shadow-light)',
          overflow: 'hidden',
          border: '1px solid var(--neutral-light)'
        }}>
          {Object.keys(skillsData).map(category => (
            <div
              key={category}
              onClick={() => setActiveTab(category)}
              style={{
                padding: '1.5rem',
                borderLeft: `4px solid ${category === activeTab ? 'var(--accent-color)' : 'transparent'}`,
                background: category === activeTab ? 'rgba(var(--accent-color-rgb), 0.05)' : 'transparent',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                borderBottom: '1px solid var(--neutral-light)'
              }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem'
              }}>
                <div style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.5rem',
                  background: category === activeTab 
                    ? 'var(--accent-color)' 
                    : 'var(--neutral-light)',
                  color: category === activeTab ? 'white' : 'var(--secondary-color)',
                  transition: 'all 0.3s ease'
                }}>
                  {categoryInfo[category].icon}
                </div>
                <div>
                  <h3 style={{
                    margin: '0 0 0.2rem 0',
                    fontSize: '1.1rem',
                    fontWeight: category === activeTab ? '600' : '500',
                    color: category === activeTab 
                      ? 'var(--accent-color)' 
                      : 'var(--primary-color)'
                  }}>
                    {categoryInfo[category].title}
                  </h3>
                  {category === activeTab && (
                    <motion.p
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      style={{
                        margin: 0,
                        fontSize: '0.85rem',
                        color: 'var(--secondary-color)',
                        lineHeight: 1.5
                      }}
                    >
                      {categoryInfo[category].description}
                    </motion.p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Skills content - right side */}
        <AnimatePresence mode="popLayout">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.4 }}
            variants={containerVariants}
            style={{
              width: '100%'
            }}
          >
            {/* Category Header */}
            <motion.div variants={itemVariants} style={{
              marginBottom: '2rem'
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                marginBottom: '1rem'
              }}>
                <div style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '12px',
                  background: 'var(--accent-color)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '1.5rem',
                  boxShadow: 'var(--box-shadow-light)'
                }}>
                  {categoryInfo[activeTab].icon}
                </div>
                <h3 style={{
                  margin: 0,
                  fontSize: '1.8rem',
                  fontWeight: '600',
                  color: 'var(--primary-color)'
                }}>
                  {categoryInfo[activeTab].title}
                </h3>
              </div>
              <p style={{
                color: 'var(--secondary-color)',
                fontSize: '1.1rem',
                margin: '0 0 1rem 0',
                lineHeight: 1.5
              }}>
                {categoryInfo[activeTab].description}
              </p>
              <div style={{
                height: '3px',
                width: '60px',
                background: 'var(--accent-color)',
                borderRadius: '2px'
              }} />
            </motion.div>

            {/* Skills Grid */}
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              gap: '1.5rem'
            }}>
              {skillsData[activeTab].map((skill, index) => (
                <motion.div
                  key={skill.name}
                  variants={itemVariants}
                  style={{
                    background: 'var(--card-background)',
                    border: '1px solid var(--neutral-light)',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    position: 'relative',
                    textAlign: 'center',
                    height: '100%',
                    boxShadow: 'var(--box-shadow-light)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                  }}
                  whileHover={{
                    transform: 'translateY(-8px)',
                    boxShadow: 'var(--box-shadow)'
                  }}
                >
                  <div style={{
                    height: '4px',
                    width: '100%',
                    background: 'var(--accent-color)'
                  }} />
                  
                  <div style={{
                    padding: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 4px)'
                  }}>
                    <h4 style={{
                      fontSize: '1.3rem',
                      fontWeight: '600',
                      margin: '0 0 1rem 0',
                      color: 'var(--primary-color)'
                    }}>
                      {skill.name}
                    </h4>
                    
                    
                    {/* Skill details */}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '0.5rem',
                      marginBottom: '1rem'
                    }}>
                      <div style={{
                        fontSize: '0.9rem',
                        color: 'var(--accent-color)',
                        fontWeight: '600',
                        textAlign: 'center'
                      }}>
                      </div>
                      
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: '0.5rem'
                      }}>
                        <span style={{
                          fontSize: '1.0rem',
                          fontWeight: '200',
                          color: 'var(--secondary-color)',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px'
                        }}>Courses Used in:</span>
                        <span style={{
                          fontSize: '1.0rem',
                          fontWeight: '600',
                          color: 'var(--primary-color)',
                          textAlign: 'center'
                        }}>{skill.years}</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Skills;