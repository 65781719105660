import React from 'react';
import { motion } from 'framer-motion';
import profilePic from './images/portfolio.jpg';

function Profile() {
  return (
    <div style={{ 
      display: 'flex',
      gap: '8rem',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      position: 'relative',
      padding: '0 0 2rem 2rem'
    }}>
      <div style={{
        flex: 1,
        maxWidth: '500px',
        position: 'relative',
        zIndex: 1
      }}>
        <div style={{ marginBottom: '2rem' }}>
          <code style={{ fontSize: '1rem' }}>class SoftwareEngineer {`{`}</code>
        </div>
        
        <h3 style={{ 
          fontSize: '3.5rem', 
          marginBottom: '2rem',
          color: 'var(--text-color)',
          fontWeight: '600',
          letterSpacing: '-0.02em'
        }}>
          Hi, I'm Alexis!
        </h3>

        <p style={{ 
          fontSize: '1.2rem', 
          marginBottom: '2rem', 
          color: 'var(--secondary-color)', 
          fontWeight: '400', 
          lineHeight: '1.8',
        }}>
          As a current Master's in Computer Science student at USC, I am driven by curiosity, creativity, 
          and a love of constantly learning new tech.  My interest in animated film from a young age has 
          motivated me down the path I am at today. I am very passionate about Computer Graphics, specifically the 
          intersection between <span className="gradient-text">Artificial Intelligence</span> and <span className="gradient-text">3D Animation</span>. Welcome to my Portfolio!
        </p>

        <div style={{ marginTop: '3rem' }}>
          <code style={{ fontSize: '1rem' }}>{`}`}</code>
        </div>
      </div>
      
      <motion.div 
        style={{
          position: 'relative',
          width: '400px',
          height: '500px'
        }}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.3 }}
      >
        <img 
          src={profilePic} 
          alt="Alexis profile"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
            border: '1px solid var(--neutral-light)'
          }}
        />
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '-20px',
          padding: '1rem',
          background: 'var(--accent-color)',
          color: 'white',
          borderRadius: '4px',
          fontSize: '0.9rem',
          fontFamily: 'JetBrains Mono, monospace'
        }}>
          USC '26
        </div>
      </motion.div>
    </div>
  );
}

export default Profile;