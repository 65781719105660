import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{
      backgroundColor: 'var(--code-bg)',
      color: 'var(--card-background)',
      padding: '1rem 1rem',
      marginTop: 'auto'
    }}>
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: '2rem'
        }}>
          
        </div>
        
        {/* Copyright */}
        <div style={{ 
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <p style={{ color: 'white', fontSize: '0.9rem', opacity: 0.7 }}>
            © {currentYear} Alexis DiNardo
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 