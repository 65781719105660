import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

const projects = [
  {
    id: 1,
    title: "Fluid Simulation",
    description: "Interactive physics based fluid simulation (built as a personal project)",
    image: "/images/fluid-sim.png",
    category: "Computer Graphics",
    tech: ["C++", "OpenGL"],
    slug: "fluid-sim"
  },
  {
    id: 2,
    title: "Ray Tracer",
    description: "Physical Ray Tracer built for my 3D Graphics and Rendering Course at USC",
    image: "/images/image5.jpg",
    category: "Computer Graphics",
    tech: ["C++"],
    slug: "ray-tracer"
  },
  {
    id: 3,
    title: "3D Shape Renderer",
    description: "An interactive modeling program built for my undergraduate Computer Graphics course at Pitt",
    image: "/images/donut.png",
    category: "Computer Graphics",
    tech: ["C", "OpenGL"],
    slug: "geo-mod"
  },
  {
    id: 4,
    title: "Computer Vision Projects",
    description: "A collection of some smaller projects I completed for my graduate Computer Vision course",
    image: "/images/vision.png",
    category: "Computer Vision",
    tech: ["Python", "PyTorch", "Tensorflow"],
    slug: "vision"
  },
  {
    id: 5,
    title: "Scraps Social Media",
    description: "A mentally healthy social media app I built for my capstone project",
    image: "/images/scrapswireframe.jpeg",
    category: "Mobile Development",
    tech: ["React Native", "Sketch", "Firebase"],
    slug: "scraps"
  },
  {
    id: 6,
    title: "startPNC",
    description: "A mobile app focused on increasing financial literacy, built during my internship at PNC",
    image: "/images/start.png",
    category: "Mobile Development",
    tech: ["Swift", "Sketch"],
    slug: "startPNC"
  }
];

function ProjectTile({ project, index }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ y: -8 }}
      className="fun-card"
      style={{
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'var(--card-background)',
        borderLeft: '4px solid var(--accent-color)'
      }}
    >
      <Link to={`/projects/${project.slug}`} style={{ textDecoration: 'none' }}>
        <div style={{ position: 'relative' }}>
          <img 
            src={project.image} 
            alt={project.title}
            style={{
              width: '100%',
              height: '300px',
              objectFit: 'cover'
            }}
          />
          <div style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'var(--code-bg)',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            color: 'var(--accent-light)',
            fontSize: '0.9rem',
            fontFamily: 'JetBrains Mono, monospace'
          }}>
            {project.category}
          </div>
        </div>

        <div style={{ padding: '2rem' }}>
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginBottom: '1rem'
          }}>
            <code style={{ 
              color: 'var(--accent-color)',
              background: 'none',
              padding: 0
            }}>project</code>
            <h3 style={{
              margin: 0,
              fontSize: '1.8rem',
              color: 'var(--text-color)',
              fontWeight: '600'
            }}>
              {project.title}
            </h3>
          </div>

          <p style={{
            fontSize: '1.1rem',
            color: 'var(--secondary-color)',
            marginBottom: '2rem',
            lineHeight: '1.6'
          }}>
            {project.description}
          </p>

          <div style={{
            display: 'flex',
            gap: '0.8rem',
            flexWrap: 'wrap'
          }}>
            {project.tech.map((tech, index) => (
              <code key={index} style={{
                background: 'var(--code-bg)',
                color: 'var(--accent-light)',
                padding: '0.3rem 0.8rem',
                borderRadius: '4px',
                fontSize: '0.9rem'
              }}>
                {tech}
              </code>
            ))}
          </div>
        </div>
      </Link>
    </motion.div>
  );
}

function Projects() {
  const location = useLocation();

  // Save scroll position when leaving the page
  useEffect(() => {
    return () => {
      sessionStorage.setItem('projectsScrollPosition', window.scrollY.toString());
    };
  }, []);

  // Restore scroll position when returning to the page
  useEffect(() => {
    const savedPosition = sessionStorage.getItem('projectsScrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition));
      sessionStorage.removeItem('projectsScrollPosition');
    }
  }, [location]);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{ 
        backgroundColor: 'var(--background-color)', 
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        padding: '6rem 0'
      }}
    >
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 4rem'
      }}>
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ marginBottom: '4rem', textAlign: 'center' }}
        >
          <h2 style={{ 
            fontSize: '3rem', 
            fontWeight: '600'
          }}>
            Projects
          </h2>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
            gap: '2rem',
            padding: '2rem 0'
          }}
        >
          {projects.map((project, index) => (
            <ProjectTile key={project.id} project={project} index={index} />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Projects;