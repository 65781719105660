import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
  const socialLinks = [
    {
      name: 'Email',
      url: 'mailto:adinardo@usc.edu',
      icon: '📧'
    },
    {
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/alexisdinardo',
      icon: '🔗'
    },
    {name: 'Resume',
    url: 'https://drive.google.com/file/d/1h2N6ZfRmXeP1JF3xRDiC4CKgC0hrOVNn/view?usp=sharing',
    icon: '💼'
  },
    {
      name: 'GitHub',
      url: 'https://github.com/alexisdinardo',
      icon: '💻'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: i => ({ 
      opacity: 1, 
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1]
      }
    })
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      style={{
        minHeight: '100vh',
        paddingTop: '120px',
        paddingBottom: '4rem',
        maxWidth: '800px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '3rem',
        padding: '120px 2rem 4rem'
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <motion.h1
          custom={0}
          variants={itemVariants}
          style={{
            fontSize: '3.5rem',
            fontWeight: '600',
            marginBottom: '1rem',
            color: 'var(--primary-color)'
          }}
        >
          Get in Touch
        </motion.h1>
        <motion.p
          custom={1}
          variants={itemVariants}
          style={{
            fontSize: '1.2rem',
            maxWidth: '700px',
            margin: '0 auto',
            color: 'var(--secondary-color)',
            lineHeight: '1.6'
          }}
        >
          Want to chat? Feel free to connect with me via email or LinkedIn!
        </motion.p>
      </div>

      <motion.div
        custom={2}
        variants={itemVariants}
        style={{
          maxWidth: '500px',
          margin: '0 auto',
          width: '100%'
        }}
      >
        <h2 style={{
          fontSize: '1.8rem',
          marginBottom: '1.5rem',
          fontWeight: '500',
          color: 'var(--primary-color)',
          textAlign: 'center'
        }}>
          Connect With Me
        </h2>
        
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.2rem'
        }}>
          {socialLinks.map((link, index) => (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="fun-card"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.5 + index * 0.1 }}
              whileHover={{ scale: 1.03, boxShadow: 'var(--box-shadow)' }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                padding: '1rem 1.5rem',
                borderRadius: '8px',
                textDecoration: 'none',
                backgroundColor: 'var(--card-background)',
                color: 'var(--text-color)',
                boxShadow: 'var(--box-shadow-light)',
                transition: 'all 0.2s ease'
              }}
            >
              <span style={{ fontSize: '1.5rem' }}>{link.icon}</span>
              <span style={{ fontSize: '1.1rem' }}>{link.name}</span>
            </motion.a>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Contact;
