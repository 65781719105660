import React from 'react';
import { motion } from 'framer-motion';
import { html } from 'framer-motion/client';

function Background() {
  const experiences = [
    {
      title: "Mobile Development Intern",
      company: "PNC Financial Services",
      period: "Summer 2023",
      description: "Developed a demo mobile iOS app in Swift to increase younger generation's financial literacy.",
      imageUrl: "/images/pnc.png" // Replace with actual image URL
    },
  ];

  const education = [
    {
      degree: "Master of Science\nComputer Science",
      institution: "University of Southern California",
      year: "Expected 2026",
      description: "Courses: 3D Graphics and Rendering, Computer Vision, Foundations of AI",
      imageUrl: "/images/usc.png" // Replace with actual image URL
    },
    {
      degree: "Bachelor of Science\nComputer Science and Interactive Design",
      institution: "University of Pittsburgh",
      year: "2020-2024",
      description: "Minor in Film and Media Studies.",
      imageUrl: "/images/pitt.png" // Replace with actual image URL
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          marginBottom: '3rem',
          textAlign: 'center'
        }}
      >
        <h2 style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          marginBottom: '1rem',
          color: 'var(--primary-color)',
          position: 'relative',
          display: 'inline-block'
        }}>
          Background
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            transition={{ duration: 0.8, delay: 0.2 }}
            style={{
              position: 'absolute',
              left: 0,
              bottom: '-5px',
              height: '3px',
              background: 'var(--accent-color)',
              borderRadius: '2px'
            }}
          />
        </h2>


      {/* Two column layout for Experience and Education */}
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
        gap: '2rem'
      }}>
        {/* Education Column - Now on the left */}
        <div>
          <motion.h3
            variants={itemVariants}
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              marginBottom: '1.2rem',
              color: 'var(--secondary-color)',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            <code style={{ background: 'none', padding: '0', color: 'var(--accent-color)' }}>
              {'{'}
            </code>
            Education
            <code style={{ background: 'none', padding: '0', color: 'var(--accent-color)' }}>
              {'}'}
            </code>
          </motion.h3>

          <motion.div
            variants={containerVariants}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.2rem'
            }}
          >

            {/* Previous Education */}
            {education.map((edu, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                style={{
                  background: 'var(--card-background)',
                  padding: '1.5rem',
                  borderRadius: '8px',
                  boxShadow: 'var(--box-shadow-light)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  cursor: 'default',
                  border: '1px solid var(--neutral-light)',
                  height: '100%'
                }}
                whileHover={{
                  y: -5,
                  boxShadow: 'var(--box-shadow)'
                }}
              >
                <div style={{
                  display: 'flex',
                  gap: '1rem',
                  marginBottom: '1rem'
                }}>
                  {/* Image container */}
                  <div style={{
                    flexShrink: 0,
                    width: '60px',
                    height: '60px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: 'var(--neutral-light)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid var(--neutral-light)'
                  }}>
                    <img 
                      src={edu.imageUrl} 
                      alt={edu.institution} 
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  
                  {/* Degree and year */}
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    <h4 style={{ 
                      fontSize: '1.2rem', 
                      fontWeight: '600', 
                      margin: '0 0 0.3rem 0',
                      color: 'var(--primary-color)',
                      whiteSpace: 'pre-line'
                    }}>
                      {edu.degree}
                    </h4>
                    <div style={{ 
                      fontSize: '1rem',
                      fontWeight: '500',
                      color: 'var(--secondary-color)'
                    }}>
                      {edu.institution}
                    </div>
                  </div>
                  
                  <span style={{ 
                    backgroundColor: 'var(--accent-color)', 
                    color: 'white', 
                    fontSize: '0.85rem',
                    padding: '0.3rem 0.6rem',
                    borderRadius: '4px',
                    fontWeight: '500',
                    height: 'fit-content',
                    whiteSpace: 'nowrap'
                  }}>
                    {edu.year}
                  </span>
                </div>
                
                <p style={{ 
                  margin: '0',
                  fontSize: '0.95rem',
                  lineHeight: '1.6',
                  color: 'var(--secondary-color)'
                }}>
                  {edu.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </div>

        {/* Work Experience Column - Now on the right */}
        <div>
          <motion.h3
            variants={itemVariants}
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              marginBottom: '1.2rem',
              color: 'var(--secondary-color)',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            <code style={{ background: 'none', padding: '0', color: 'var(--accent-color)' }}>
              {'{'}
            </code>
            Work Experience
            <code style={{ background: 'none', padding: '0', color: 'var(--accent-color)' }}>
              {'}'}
            </code>
          </motion.h3>

          <motion.div
            variants={containerVariants}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.2rem'
            }}
          >
            {experiences.map((exp, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                style={{
                  background: 'var(--card-background)',
                  padding: '1.5rem',
                  borderRadius: '8px',
                  boxShadow: 'var(--box-shadow-light)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  cursor: 'default',
                  border: '1px solid var(--neutral-light)',
                  height: '100%'
                }}
                whileHover={{
                  y: -5,
                  boxShadow: 'var(--box-shadow)'
                }}
              >
                <div style={{
                  display: 'flex',
                  gap: '1rem',
                  marginBottom: '1rem'
                }}>
                  {/* Image container */}
                  <div style={{
                    flexShrink: 0,
                    width: '60px',
                    height: '60px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: 'var(--neutral-light)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid var(--neutral-light)'
                  }}>
                    <img 
                      src={exp.imageUrl} 
                      alt={exp.company} 
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  
                  {/* Title and period */}
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    <h4 style={{ 
                      fontSize: '1.2rem', 
                      fontWeight: '600', 
                      margin: '0 0 0.3rem 0',
                      color: 'var(--primary-color)'
                    }}>
                      {exp.title}
                    </h4>
                    <div style={{ 
                      fontSize: '1rem',
                      fontWeight: '500',
                      color: 'var(--secondary-color)'
                    }}>
                      {exp.company}
                    </div>
                  </div>

                  <span style={{ 
                    backgroundColor: 'var(--accent-color)', 
                    color: 'white', 
                    fontSize: '0.85rem',
                    padding: '0.3rem 0.6rem',
                    borderRadius: '4px',
                    fontWeight: '500',
                    height: 'fit-content',
                    whiteSpace: 'nowrap'
                  }}>
                    {exp.period}
                  </span>
                </div>
                
                <p style={{ 
                  margin: '0',
                  fontSize: '0.95rem',
                  lineHeight: '1.6',
                  color: 'var(--secondary-color)'
                }}>
                  {exp.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default Background;