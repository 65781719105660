import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navStyle = {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
    padding: scrolled ? '0.8rem 4rem' : '1.2rem 4rem',
    backgroundColor: scrolled ? 'rgba(248, 250, 252, 0.98)' : 'var(--background-color)',
    backdropFilter: scrolled ? 'blur(10px)' : 'none',
    transition: 'all 0.3s ease',
    borderBottom: scrolled ? '1px solid var(--neutral-light)' : 'none',
    boxShadow: scrolled ? 'var(--box-shadow-light)' : 'none'
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'var(--secondary-color)',
    fontSize: '1rem',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    transition: 'all 0.2s ease',
    position: 'relative'
  };

  const activeLinkStyle = {
    ...linkStyle,
    backgroundColor: 'var(--accent-color)',
    color: 'var(--card-background)'
  };

  const logoStyle = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  };

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={navStyle}
    >
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Link to="/" style={logoStyle}>
          <code style={{ 
            color: 'var(--accent-color)',
            fontSize: '1.1rem',
            background: 'none',
            padding: 0,
            fontWeight: '600'
          }}>
            ad
          </code>
          <span style={{
            fontSize: '1.1rem',
            fontWeight: '600',
            color: 'var(--text-color)'
          }}>
            AlexisDiNardo
          </span>
        </Link>

        <div style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center'
        }}>
          <Link 
            to="/" 
            style={location.pathname === '/' ? activeLinkStyle : linkStyle}
          >
            <code style={{ 
              background: 'none', 
              padding: 0,
              color: location.pathname === '/' ? 'var(--card-background)' : 'currentColor'
            }}>home</code>
          </Link>
          <Link 
            to="/projects" 
            style={location.pathname === '/projects' ? activeLinkStyle : linkStyle}
          >
            <code style={{ 
              background: 'none', 
              padding: 0,
              color: location.pathname === '/projects' ? 'var(--card-background)' : 'currentColor'
            }}>projects</code>
          </Link>
          <Link 
            to="/contact" 
            style={location.pathname === '/contact' ? activeLinkStyle : linkStyle}
          >
            <code style={{ 
              background: 'none', 
              padding: 0,
              color: location.pathname === '/contact' ? 'var(--card-background)' : 'currentColor'
            }}>contact</code>
          </Link>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navbar;
