import React from 'react';
import { motion } from 'framer-motion';

function Interests() {
  return (
    <div style={{
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem 2rem',
      position: 'relative'
    }}>
      {/* Section header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          marginBottom: '3rem',
          textAlign: 'center'
        }}
      >
        <h2 style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          marginBottom: '1rem',
          color: 'var(--primary-color)',
          position: 'relative',
          display: 'inline-block'
        }}>
          Beyond Tech
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            transition={{ duration: 0.8, delay: 0.2 }}
            style={{
              position: 'absolute',
              left: 0,
              bottom: '-5px',
              height: '3px',
              background: 'var(--accent-color)',
              borderRadius: '2px'
            }}
          />
        </h2>
      </motion.div>

      {/* Main content card */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          background: 'var(--card-background)',
          borderRadius: '12px',
          boxShadow: 'var(--box-shadow-light)',
          border: '1px solid var(--neutral-light)',
          overflow: 'hidden'
        }}
      >
        <div style={{
          height: '4px',
          width: '100%',
          background: 'var(--accent-color)'
        }} />
        
        <div style={{
          padding: '2rem'
        }}>
          {/* Introduction */}
          <div style={{
            background: 'rgba(var(--accent-color-rgb), 0.05)',
            borderRadius: '8px',
          }}>
            <p style={{
              fontSize: '1.2rem',
              lineHeight: '1.8',
              color: 'var(--secondary-color)',
              marginLeft: '1.5rem',
              marginBottom: '1rem'
            }}>
              When I'm not coding, you'll find me exploring the intersection of art, movement, and nature.
            </p>
          </div>

          {/* Passions */}
          <div style={{
            marginBottom: '2rem'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              marginBottom: '1.5rem'
            }}>
              <span style={{ 
                fontSize: '1.2rem',
                color: 'var(--primwhitary-color)',
                fontWeight: '600'
              }}>get passions( )</span>
              <code style={{ color: 'white' }}>{` {`}</code>
            </div>

            {/* Two column grid for passions and images */}
            <div style={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '2rem',
              marginLeft: '1.5rem'
            }}>
              {/* Left column - Interests list */}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem'
              }}>
                <div>
                  <code style={{ color: 'var(--accent-light)' }}>// Dance</code>
                  <p style={{
                    margin: '0.5rem 0',
                    fontSize: '1.1rem',
                    color: 'var(--secondary-color)',
                    lineHeight: '1.6'
                  }}>
                    I have been dancing since I was two and was even lucky enough to dance through college on the University of Pittsburgh Dance Team!
                  </p>
                </div>
                
                <div>
                  <code style={{ color: 'var(--accent-light)' }}>// Hiking</code>
                  <p style={{
                    margin: '0.5rem 0',
                    fontSize: '1.1rem',
                    color: 'var(--secondary-color)',
                    lineHeight: '1.6'
                  }}>
                    Hiking is another one of my passions and I've set a goal to visit all 63 National Parks in the U.S.!
                  </p>
                </div>

                <div>
                  <code style={{ color: 'var(--accent-light)' }}>// Film</code>
                  <p style={{
                    margin: '0.5rem 0',
                    fontSize: '1.1rem',
                    color: 'var(--secondary-color)',
                    lineHeight: '1.6'
                  }}>
                    I studied Film and Media at Pitt. Disney and Pixar specifically have a very special place in my heart and inspired me to love film from a very young age!
                  </p>
                </div>
              </div>

              {/* Right column - Images */}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                width: '100%',
                maxWidth: '800px',
                margin: '0 auto'
              }}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="fun-card"
                  style={{
                    position: 'relative',
                    paddingTop: '75%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                    background: 'var(--card-background)',
                    boxShadow: 'var(--box-shadow-light)',
                    flex: 1
                  }}
                >
                  <img 
                    src="/images/dance.jpg" 
                    alt="Me dancing"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  className="fun-card"
                  style={{
                    position: 'relative',
                    paddingTop: '75%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                    background: 'var(--card-background)',
                    boxShadow: 'var(--box-shadow-light)',
                    flex: 1
                  }}
                >
                  <img 
                    src="/images/hiking.jpg" 
                    alt="Me hiking"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </motion.div>
              </div>
            </div>

            <code style={{ color: 'white' }}>{`} `}</code>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Interests;